<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="projectId && !exception">
          <display-container :label="`Der Download für Projekt ${projectId} steht bereit`">
            <v-btn
              :loading="downloading"
              :disabled="downloading"
              color="blue-grey"
              class="ma-2 white--text"
              @click="downloadFile"
            >
              Download {{ exportFilename }}
              <v-icon
                right
                dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </display-container>
        </div>
        <div v-if="exception">
          <display-container :label="`Beim erzeugen der Datei ist ein Fehler aufgetreten`">
            <p>Bitte diese Meldung der Entwicklung melden.</p>
            <v-alert
              border="right"
              colored-border
              type="error"
              elevation="2"
            >
              {{ exception }}
            </v-alert>
          </display-container>
        </div>
        <div class="margin-bottom-2 align-buttons-right">
          <v-btn
            :disabled="!claimed || ((!downloadedOnce || downloading) && !exception)"
            color="primary"
            @click="completeTask"
          >
            Aufgabe schließen
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionGncExportReady',
  components: {
    DisplayContainer: () => import('@/components/elements/DisplayContainer'),
    TaskBlocker: () => import('@/task/TaskBlocker')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    projectId: null,
    exportFilename: null,
    exception: null,
    requestedByUser: null,
    downloadedOnce: false,
    downloading: false
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  created: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    loadTaskData: function (taskId) {
      HTTP.get(`/task/${taskId}/variables`).then(({ status, data }) => {
        if (status !== 200) {
          return this.showMessage(
            `Prozessvariablen konnten nicht geladen werden. HTTP Status ${status}`
          );
        }

        for (const variable of Object.keys(data)) {
          console.log(variable, data[variable].value);
          this[variable] = data[variable].value;
        }
      });
    },
    downloadFile: function () {
      this.downloading = true;
      HTTP.get(`/gncReport/downloadAsyncGncReport?taskId=${this.data.id}`, {
        responseType: 'blob'
      })
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.exportFilename;
          link.click();
          URL.revokeObjectURL(link.href);

          this.downloadedOnce = true;
        })
        .catch(() => {
          this.showMessage('Die Datei konnte nicht heruntergeladen werden');
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    showMessage: function (message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
    completeTask: function () {
      console.log('completeTask??');
      HTTP.post(
        `/gncReport/finishAsyncGncReportTask?taskId=${this.data.id}`,
        {}
      )
        .then(() => {
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showNotification(
            'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!'
          )
        );
    }
  }
};
</script>
